import React, { useState } from "react";
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import DiscordIcon from '../../assets/img/discord.png';

interface FooterProps {
  children?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <div className="mt-auto py-4 text-gray-400 text-sm px-2">
      <div className="flex justify-between items-center relative">
        <p className="flex-shrink-0" style={{color: "#000000"}}>Soniccoin {/* */}2024</p>
        <div className="text-gray-400 text-center text-sm" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
         <div onClick={() => window.open("https://t.me/SoniccoinTG")}>
         <TelegramIcon style={{width: "20px", height: "20px", marginRight: "10px", cursor: "pointer", color: "#000000"}} className="text-gray-400" />
         </div>
         <div onClick={() => window.open("https://x.com/SoniccoinApp")}>
         <XIcon style={{width: "20px", height: "20px", marginRight: "10px", cursor: "pointer", color: "#000000"}} className="text-gray-400" />
         </div>
         <img onClick={() => window.open("https://discord.gg/9np7zY7r3n")} style={{width: "20px", height: "20px", cursor: "pointer"}} src={DiscordIcon} alt="Discord" />
        </div>
      </div>
    </div>
  );
};

export default Footer;