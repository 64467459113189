import React, { useEffect, useRef, useState } from "react";
import FaqCard from "./FaqCard";
import HalvingTable from "./HalvingTable";

interface HowItWorksProps {
  open: boolean;
  onClose: () => void;
}

const HowItWorks: React.FC<HowItWorksProps> = ({ open, onClose }) => {

  const [faqCardOpen, setFaqCardOpen] = useState(-1);

  const modalRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add event listener to detect clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when component is unmounted or `open` changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  // Return null if the modal is not open
  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="text-sm fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg2 translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-black p-4 text-center"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "1px solid", maxHeight: "80vh", overflowY: "auto" }}
      >
        <div className="flex flex-col space-y-1.5 sm:text-left text-center">
          <h2
            style={{ fontFamily: "Urbanist, ui-sans-serif", fontWeight: 700, fontSize: "2.2rem", paddingLeft: "1rem", paddingTop: "1.5rem" }}
          >
            How It Works
          </h2>
        </div>
        <div className="bg-primary text-black p-4 space-y-4">
          <div style={{ textAlign: "left", fontSize: "16px" }}>
          Soniccoin Token Address: <a href="https://testnet.soniclabs.com/address/0x92e9fe9a750e84a1050b2e7585a233276c0332a9">0x92E9fe9a750e84A1050B2e7585a233276c0332A9</a>
          </div>
          <div style={{ textAlign: "left", fontSize: "16px" }}>SonicCoin (SONIC) is a Bitcoin-inspired ERC20 token with a one-minute block time. In each block, one miner is randomly selected to receive SonicCoin, with their chances based on the amount of Mining Power they hold. Mining Power is determined by the number of interactions with the SonicCoin smart contract during that block—the more interactions, the higher their chances of earning the reward.</div>
          <div style={{ textAlign: "left", fontSize: "16px" }}>Similar to Bitcoin’s halving, SonicCoin block rewards are halved after a set period, starting with an initial reward of 200 SONIC per block. The first halving happens after around 7 days. After each halving, the time period doubles, so the second halving occurs after roughly 14 days.</div>
          <div className="flex flex-col space-y-1.5 sm:text-left text-center" style={{marginTop: "40px"}}>
            <h2
              style={{ fontFamily: "Urbanist, ui-sans-serif", fontWeight: 700, fontSize: "2.2rem" }}
            >
              Halving Schedule
            </h2>
          </div>
          <HalvingTable />
          <FaqCard
            title="How do I get SonicCoin?"
            content="You can get SonicCoin by mining BLOCKs. The more Mining Power you have in a given BLOCK, the higher your chances of receiving SonicCoin."
            onClick={() => faqCardOpen === 0 ? setFaqCardOpen(-1) : setFaqCardOpen(0)}
            open={faqCardOpen === 0 || faqCardOpen === null || faqCardOpen === -1}
          />
          <FaqCard
            title="How many SonicCoin do I get for mining in a BLOCK?"
            content="You will receive 200 SonicCoin for mining in a BLOCK at launch. However, the reward halves based on a certain period."
            onClick={() => faqCardOpen === 1 ? setFaqCardOpen(-1) : setFaqCardOpen(1)}
            open={faqCardOpen === 1}
          />
          <FaqCard
            title="What is Mining Power?"
            content="When a miner participates in mining a BLOCK, they earn Mining Power in that BLOCK, measured in units of V. The more Mining Power a miner has, the higher their chances of being rewarded during the Reward Phase."
            onClick={() => faqCardOpen === 2 ? setFaqCardOpen(-1) : setFaqCardOpen(2)}
            open={faqCardOpen === 2}
          />
          <FaqCard
            title="How much does Mining Power cost?"
            content="Mining Power costs 0.2 $S per energy. The cost of Mining Power is fixed and does not change. Additionally, generating more Mining Power will incur higher gas fees."
            onClick={() => faqCardOpen === 3 ? setFaqCardOpen(-1) : setFaqCardOpen(3)}
            open={faqCardOpen === 3}
          />
          <FaqCard
            title="How much Mining Power do I need?"
            content="You need at least 1 V of Mining Power to be eligible for the Reward Phase. However, the more Mining Power you have, the higher your chances of being rewarded."
            onClick={() => faqCardOpen === 4 ? setFaqCardOpen(-1) : setFaqCardOpen(4)}
            open={faqCardOpen === 4}
          />
          <FaqCard
            title="Why does each BLOCK have two phases?"
            content="SonicCoin is designed to reward miners for participating in Sonic network activities in a random manner. Having two phases ensures the fairness of the random selection process."
            onClick={() => faqCardOpen === 5 ? setFaqCardOpen(-1) : setFaqCardOpen(5)}
            open={faqCardOpen === 5}
          />
          <FaqCard
            title="What is Future Mine?"
            content="Future Mine allows you to mine BLOCKs ahead of time, making it easy to participate in future BLOCKs without manually submitting transactions for each BLOCK."
            onClick={() => faqCardOpen === 6 ? setFaqCardOpen(-1) : setFaqCardOpen(6)}
            open={faqCardOpen === 6}
          />
          <FaqCard
            title="What do the two block numbers on the main page mean?"
            content="The smaller block number indicates the BLOCK in the Reward Phase, while the larger block number represents the BLOCK in the Mining Phase."
            onClick={() => faqCardOpen === 7 ? setFaqCardOpen(-1) : setFaqCardOpen(7)}
            open={faqCardOpen === 7}
          />
        </div>

        {/* <button className="text-slate-50 hover:text-slate-50 cursor-pointer w-fit justify-self-center" onClick={onClose}>
        [I'm ready to pump]
      </button> */}
      </div>
    </div>
  );
}
export default HowItWorks;