import React from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const FaqCardOuter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 10px;
    opacity: 1;
    cursor: pointer;
    background-color: #ffedd6;
    border-radius: .25rem;
    // border: 1px solid #E5E7EB;
    transition: background-color .15s ease;
    color: #000000';
    padding: .75rem 15px;
`;

interface FaqCardProps {
    title: string;
    content: string;
    onClick: () => void;
    open: boolean;
}

const FaqCard: React.FC<FaqCardProps> = ({
    title,
    content,
    onClick,
    open
}) => {

    return (
        <FaqCardOuter onClick={onClick}>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <h3 style={{display: "flex", flex: "1", fontWeight: "700"}}>{title}</h3>
                <p className="text-primary">{open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}</p>
            </div>
            <p style={{marginTop: "20px", textAlign: "left", opacity: "0.5", color: "#000000"}} className={`text-sm ${open ? "" : "hidden"}`}>{content}</p>
        </FaqCardOuter>
    );
}
export default FaqCard;