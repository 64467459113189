import React from "react";
import styled from "styled-components";
import { Col } from "antd";
import NextBlockLeaderboardPlayer from "./NextBlockLeaderboardPlayer";
import BoltIcon from '@mui/icons-material/Bolt';
import HikingIcon from '@mui/icons-material/Hiking';
import { useMediaQuery } from "@mui/material";

interface LeftSidebarProps {
    currentBlockNumber: any;
    uniqueWalletsCount: any;
    nextBlockTotalEnergy: any;
    sortedUniqueWallets: any;
    nextBlockWalletsList: any;
}

const LeftSidebar: React.FC<LeftSidebarProps> = ({
    currentBlockNumber,
    uniqueWalletsCount,
    nextBlockTotalEnergy,
    sortedUniqueWallets,
    nextBlockWalletsList
}) => {

    const isMobile = useMediaQuery('(max-width: 580px)');

    return (
        <Col span={isMobile ? 24 : 6} style={{
            borderLeft: '1px solid #000000',
            borderRight: '1px solid #000000',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // height: '100%',
            overflow: 'hidden'
        }}>
            <div style={{
                padding: '10px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFFFFF',
                fontSize: '20px',
                fontWeight: 'bold',
                fontFamily: "Urbanist, ui-sans-serif",
                width: '100%'
            }}>
                <span style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: "1", paddingLeft: "10px", color: "#000000" }}>
                    #{currentBlockNumber}
                </span>
                <HikingIcon style={{ fontSize: '24px', marginRight: '0 5px',  color: "#000000" }} />
                <span style={{ color: "#000000"}}>{uniqueWalletsCount}</span>
                <BoltIcon style={{ fontSize: '24px', marginRight: '0 5px',  color: "#000000" }} />
                <span style={{ paddingRight: '10px',  color: "#000000" }}>{nextBlockTotalEnergy ? nextBlockTotalEnergy.toString() : "-"}</span>
            </div>
            <div style={{ overflowY: 'auto', width: '100%', height: '100%' }}>
                {Array.from(sortedUniqueWallets).slice(0, 10).map((userAddress, index) => (
                    <NextBlockLeaderboardPlayer
                        key={index}
                        index={index}
                        userAddress={userAddress as string}
                        walletsList={nextBlockWalletsList as any}
                    />
                ))}
            </div>
        </Col>
    );
}
export default LeftSidebar;