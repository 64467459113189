import React, { useEffect, useRef, useState } from "react";
import { useAccount, useBalance, useReadContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { SONICCOIN_ADDRESS } from "../../../utils/constants";
import { formatUnits, parseUnits } from "viem";
import { notifyError, notifySuccess } from "../../../hooks/utils";
import ButtonMenu from "../../../components/ButtonMenu";
import EthCoinContractABI from "../../../abis/ethcoin.abi.json";
import FaqCard from "./FaqCard";
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components";

const StatsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #ffffff16;
`;

interface StatsModalProps {
  open: boolean;
  onClose: () => void;
  currentBlockNumber: any;
  timeUntilNextBlock: any;
  miningReward: any;
  timeUntilNextHalving: any;
  nextHalvingBlock: any;
  totalSupply: any;
}

const StatsModal: React.FC<StatsModalProps> = ({ 
  open, 
  onClose,
  currentBlockNumber,
  timeUntilNextBlock,
  miningReward,
  timeUntilNextHalving,
  nextHalvingBlock,
  totalSupply
}) => {

  const account = useAccount();

  const userBalance = useBalance()

  const { data: miningCost } = useReadContract({
    abi: EthCoinContractABI,
    address: SONICCOIN_ADDRESS,
    functionName: 'mineCost',
    args: [], // args is an array of arguments to pass to the function
  });

  const [totalBlocksToMine, setTotalBlocksToMine] = useState(1);
  const [energyPerBlock, setEnergyPerBlock] = useState(1);

  const [placingTrade, setPlacingTrade] = useState(false);

  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });

  // Log the transaction arguments and hash for debugging
  useEffect(() => {
    if (hash) {
      console.log("Transaction hash:", hash);
      notifySuccess("Future mining placed!");
    }
    if (error) {
      console.error("Error during contract write:", error);
      notifyError("An error occurred while trying to future mine.");
    }
    if (isConfirmed) {
      console.log("Transaction confirmed!");
      notifySuccess("Future mining confirmed!");
    }
  }, [hash, error]);


  const handleFutureMine = async () => {

    if (!account) {
      // Redirect to login or show a message
      alert("You need to sign in to trade.");
      return;
    }

    try {
      setPlacingTrade(true);

      const transactionArgs = {
        address: SONICCOIN_ADDRESS,
        abi: EthCoinContractABI,
        functionName: 'futureMine',
        args: [
          energyPerBlock,
          totalBlocksToMine
        ],
        value: parseUnits((energyPerBlock * totalBlocksToMine * Number(formatUnits(miningCost as any ?? "0", 18))).toString(), 18), // 0.0001 $S
      };

      writeContract(transactionArgs as any);
    } catch (error) {
      console.error("Error placing trade:", error);
      notifyError("An error occurred while trying to place trade.");
    } finally {
      setPlacingTrade(false);
    }
  };

  const [faqCardOpen, setFaqCardOpen] = useState(-1);


  // MODAL OPEN AND CLOSE
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="text-sm fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "none" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: "1", justifyContent: "left", display: "flex" }}>Stats</div>
          <div onClick={onClose}><CloseIcon /></div>
        </div>


        <StatsRow>
          <div style={{ color: "#000000"}}>Current Block</div>
          <div>#{currentBlockNumber ? currentBlockNumber.toString() : "-"}</div>
        </StatsRow>
        <StatsRow>
          <div style={{ color: "#000000"}}>Next Block In</div>
          <div>{timeUntilNextBlock}</div>
        </StatsRow>
        <StatsRow>
          <div style={{ color: "#000000"}}>Miner Reward</div>
          <div>{miningReward ? formatUnits(miningReward as any, 18).toString() : "-"} SONIC</div>
        </StatsRow>
        <StatsRow>
          <div style={{ color: "#000000"}} >Next Halving Block</div>
          <div>{Number(nextHalvingBlock).toLocaleString("en-US")}</div>
        </StatsRow>
        <StatsRow>
          <div style={{ color: "#000000"}}>Next Halving In</div>
          <div>~{timeUntilNextHalving}</div>
        </StatsRow>
        <StatsRow>
          <div>Mined SONIC</div>
          <div>{(Number(formatUnits(totalSupply as any, 18)) / 1000).toLocaleString("en-US", {maximumFractionDigits: 0})}k/21,000k</div>
        </StatsRow>
        {/* <StatsRow>
          <div>Unique Miners</div>
          <div></div>
        </StatsRow>
        <StatsRow>
          <div>Total $S Burned</div>
          <div></div>
        </StatsRow>
        <StatsRow>
          <div>Total Mining Power</div>
          <div></div>
        </StatsRow> */}
      </div>
    </div>
  );
}
export default StatsModal;