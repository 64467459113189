import NotFoundImg from "../assets/img/not-found.png"
export const SCAN_URL = "https://sonicscan.org/"

export const SONICCOIN_ADDRESS = "0x99995a54FD82C16E1428690C0D6e3bEd1e0d0Cb9"

export function getImageFormattedUrl(isUser: boolean, url: string, width: number, additional: string) {
    if (url === "") {
        return NotFoundImg
    }

    return `${url}${width > 0 ? `?img-width=${width}&img-dpr=2` : ""}${additional}`
}