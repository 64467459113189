import React from "react";
import styled from "styled-components";

const TableContainer = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableHeader = styled.div`
  display: table-cell;
  padding: 10px;
  font-weight: bold;
  background-color: transparent;
  border: 1px solid #424242;
  text-align: left;
`;

const TableCell = styled.div`
  display: table-cell;
  padding: 10px;
  border: 1px solid #424242;
  text-align: left;
`;

const data = [
    { halving: 0, reward: 200, days: 0, cumulativeDays: 0 },
    { halving: 1, reward: 100, days: 7, cumulativeDays: 7 },
    { halving: 2, reward: 50, days: 14, cumulativeDays: 21 },
    { halving: 3, reward: 25, days: 28, cumulativeDays: 49 },
    { halving: 4, reward: 12.5, days: 56, cumulativeDays: 105 },
    { halving: 5, reward: 6.25, days: 112, cumulativeDays: 217 },
    { halving: 6, reward: 3.125, days: 224, cumulativeDays: 441 },
    { halving: 7, reward: 1.5625, days: 448, cumulativeDays: 889 },
];

const HalvingTable: React.FC = () => {
  return (
    <TableContainer>
      <TableRow>
        <TableHeader>Halving</TableHeader>
        <TableHeader>SONIC reward</TableHeader>
        <TableHeader>Days</TableHeader>
        <TableHeader>Cumulative Days</TableHeader>
      </TableRow>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.halving}</TableCell>
          <TableCell>{row.reward}</TableCell>
          <TableCell>{row.days}</TableCell>
          <TableCell>{row.cumulativeDays}</TableCell>
        </TableRow>
      ))}
    </TableContainer>
  );
};

export default HalvingTable;
