import { createPublicClient, http } from 'viem'
import { fantomSonicTestnet } from 'viem/chains'
 
export const publicClient = createPublicClient({ 
  chain: fantomSonicTestnet,
  transport: http("https://rpc.testnet.soniclabs.com"),
  multicall3: {
    address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    blockCreated: 60,
 },
})