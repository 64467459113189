import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageContainer from "../../components/PageContainer/PageContainer";
import { SONICCOIN_ADDRESS } from "../../utils/constants";
import EthCoinContractABI from "../../abis/ethcoin.abi.json";
import HistoryBetCard from "./components/HistoryBetCard";
import { publicClient } from "../../client";
import { useReadContract } from "wagmi";
import Footer from "../../components/Footer";

const PaginationButton = styled.button<{ disabled?: boolean, selected?: boolean }>`
    padding: 0.5rem;
    border: ${({ selected }) => (selected ? 'none' : '1px solid #424242')};
    background: ${({ selected }) => (selected ? '#EC5409' : '#2e2e2e')};
    color: ${({ selected }) => (selected ? '#000' : '#c9c9c9')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    min-width: 2.5rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: .25rem;
    &:hover {
        background: ${({ selected }) => (selected ? '#EC5409' : '#3b3b3b')};;
    }
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  gap: 1px;
  color: #fff;
  text-align: left;
  font-size: 14px;
  width: 100%;
  overflow-x: auto;
`;

const Cell = styled.div<{ isHeader?: boolean }>`
  padding: 10px;
  border-bottom: 1px solid #424242;
  font-weight: ${({ isHeader }) => (isHeader ? '700' : 'normal')};
  color: white;
`;

const HistoryPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const tokensPerPage = 15;
    const [blockData, setBlockData] = useState([] as any);


    const { data: currentBlockNumber } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'blockNumber',
        args: [],
    });

    const fetchBatchData = async (blockNumbers: number[]) => {
        try {
            const formattedData = await Promise.all(
                blockNumbers.map(async (blockNumber) => {
                    // Fetch miners of the block
                    const miners = await publicClient.readContract({
                        address: SONICCOIN_ADDRESS,
                        abi: EthCoinContractABI,
                        functionName: "minersOfBlock",
                        args: [blockNumber],
                    });
    
                    // Fetch selected winner of the block
                    const winner = await publicClient.readContract({
                        address: SONICCOIN_ADDRESS,
                        abi: EthCoinContractABI,
                        functionName: "selectedMinerOfBlock",
                        args: [blockNumber],
                    });
    
                    return {
                        blockNumber,
                        miners: miners as any,
                        winner: winner as any,
                    };
                })
            );
    
            setBlockData(formattedData);
        } catch (error) {
            console.error("Error fetching batch data:", error);
        }
    };
    

    const totalPages = Math.ceil(Number(currentBlockNumber) / tokensPerPage);

    // useEffect(() => {
    //     const blockNumbers = Array.from({ length: tokensPerPage }, (_, i) => (currentPage - 1) * tokensPerPage + i + 1);
    //     fetchBatchData(blockNumbers);
    // }, [currentPage]);

    useEffect(() => {
        // Start from the latest block number and count down
        const startBlockNumber = Number(currentBlockNumber);
        const blockNumbers = Array.from(
            { length: tokensPerPage },
            (_, i) => startBlockNumber - (currentPage - 1) * tokensPerPage - i
        ).filter(blockNumber => blockNumber > 0); // Ensure no negative block numbers
    
        fetchBatchData(blockNumbers);
    }, [currentPage, currentBlockNumber]);

    const handleInitialPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    return (
        <PageContainer>
            <main className="h-full" style={{ minHeight: '100vh' }}>
                <div className="grid h-fit md:gap-12 gap-4">
                    <h1 style={{ fontSize: "2.2rem", fontWeight: 700, color: "#000000", width: "100%", textAlign: "left" }}>
                        Block History
                    </h1>
                    <TableContainer>
                        <Cell style={{ color: "#000000" }} isHeader>Block Number</Cell>
                        <Cell style={{ color: "#000000" }} isHeader>Miner</Cell>
                        <Cell style={{ color: "#000000" }} isHeader>Mining Power</Cell>
                        <Cell style={{ color: "#000000" }} isHeader>Total Mining Power</Cell>
                        <Cell style={{ color: "#000000" }} isHeader># of Miners</Cell>
                        {blockData.map((data: any, index: number) => (
                            <HistoryBetCard
                                key={index}
                                blockNumber={data.blockNumber}
                                miners={data.miners}
                                winner={data.winner}
                            />
                        ))}
                    </TableContainer>
                    <div className="w-full flex justify-center mt-4">
                        <div className="justify-self-end mb-20">
                            <div className="flex justify-center space-x-2 text-slate-50">
                                <PaginationButton
                                    onClick={handleInitialPage}
                                    disabled={currentPage === 1}
                                    // className={`text-lg ${currentPage === 1 ? "text-slate-400 cursor-not-allowed" : "text-slate-50 hover:font-bold"}`}
                                >
                                    &lt;&lt;
                                </PaginationButton>
                                <PaginationButton
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    // className={`text-lg ${currentPage === 1 ? "text-slate-400 cursor-not-allowed" : "text-slate-50 hover:font-bold"}`}
                                >
                                    &lt;
                                </PaginationButton>
                                {totalPages > 0 && (
                                    Array.from({ length: totalPages }, (_, i) => i + 1).slice(currentPage < 6 ? 0 : currentPage - 3, currentPage < 6 ? 6 : currentPage + 3).map((page) => (
                                        <PaginationButton
                                            key={page}
                                            onClick={() => setCurrentPage(page)}
                                            selected={currentPage === page}
                                            // className={`text-lg ${currentPage === page ? "text-slate-400" : "text-slate-50 hover:font-bold"}`}
                                        >
                                            {page}
                                        </PaginationButton>
                                    )))}
                                {/* <span className="text-lg">{currentPage}</span> */}
                                <PaginationButton
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                    // className={`text-lg ${currentPage === totalPages ? "text-slate-400 cursor-not-allowed" : "text-slate-50 hover:font-bold"}`}
                                >
                                    &gt;
                                </PaginationButton>
                                <PaginationButton
                                    onClick={handleLastPage}
                                    disabled={currentPage === totalPages}
                                    // className={`text-lg ${currentPage === totalPages ? "text-slate-400 cursor-not-allowed" : "text-slate-50 hover:font-bold"}`}
                                >
                                    &gt;&gt;
                                </PaginationButton>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </PageContainer>
    );
};
export default HistoryPage;
