import React from "react";
import styled from "styled-components";
import { zeroAddress } from "viem";
import BoltIcon from '@mui/icons-material/Bolt';

interface HistoryBetCardProps {
    blockNumber: number;
    miners: string[];
    winner: string;
}

const Cell = styled.div`
  padding: 10px;
  border-bottom: 1px solid #424242;
  font-weight: normal;
  color: black;
`;

const HistoryBetCard: React.FC<HistoryBetCardProps> = ({
    blockNumber,
    miners,
    winner,
}) => {
    const count = miners.filter((miner) => miner === winner).length;
    const percentage = ((count / miners.length) * 100).toFixed(2);
    const uniqueWalletsCount = new Set(miners).size;

    return (
        <>
            <Cell>{blockNumber}</Cell>
            <Cell>{winner === zeroAddress ? "(Pending...)" : winner.slice(0, 6)+"..."+winner.slice(-4)}</Cell>
            {winner === zeroAddress ? <Cell>-</Cell> : <Cell>{count}<BoltIcon style={{ fontSize: '18px' }} />{`(${percentage}%)`}</Cell>}
            <Cell>{miners.length}</Cell>
            <Cell>{uniqueWalletsCount}</Cell>
        </>
    );
};

export default HistoryBetCard;
