// src/App.js
import React, { useState, useEffect } from 'react';
import '@rainbow-me/rainbowkit/styles.css';
import { getCookie } from './utils';
import {
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header/Header';
import { config } from './config';
import styled from 'styled-components';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainPage from './views/MainPage';
import HistoryPage from './views/HistoryPage';
import "./App.css";
import { theme, ConfigProvider } from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Announcement from './components/Announcement';

const queryClient = new QueryClient();

const AppContainer = styled.div`
  text-align: center;
  max-width: 82.5rem; // Adjust as necessary
  margin: 0 auto;
  padding: 0 20px; // Adds padding on the left and right for smaller screens
  background-color: transparent; // Ensure this aligns with your background color theme
`;


const App = () => {

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
            <ConfigProvider
            >
              <AppContainer className='bg-primary'>
                {/* <Announcement /> */}
                <Header />
                <Router>
                  <Switch>
                    <Route exact path="/" component={MainPage} />
                    <Route path="/history" component={HistoryPage} />
                  </Switch>
                </Router>
                <ToastContainer
                  theme='dark' />
              </AppContainer>
            </ConfigProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
