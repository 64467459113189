import React from "react";
import BoltIcon from '@mui/icons-material/Bolt';
import { useEnsName, useReadContract } from "wagmi";
import PersonIcon from '@mui/icons-material/Person';
import { zeroAddress } from "viem";
import { SONICCOIN_ADDRESS } from "../../../utils/constants";
import EthCoinContractABI from "../../../abis/ethcoin.abi.json";

interface PreviousBlockWinnerCardProps {
    index: number;
    blockNumber: number;
}

const PreviousBlockWinnerCard: React.FC<PreviousBlockWinnerCardProps> = ({
    index,
    blockNumber
}) => {
    const { data: currentBlockWalletsList } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'minersOfBlock',
        args: [blockNumber],
    });

    const { data: winner } =  useReadContract({
        address: SONICCOIN_ADDRESS,
        abi: EthCoinContractABI,
        functionName: "selectedMinerOfBlock",
        args: [blockNumber]
    });
    
    const { data: ensName } = useEnsName({ address: winner as any })
    // count how much unique wallets are in the list
    const currentUniqueWallets = new Set(currentBlockWalletsList as any);
    const currentUniqueWalletsCount = currentUniqueWallets.size;

    // count number of occurences of userAddress in walletsList
    let count = 0;
    for (let i = 0; i < (currentBlockWalletsList as any)?.length; i++) {
        if ((currentBlockWalletsList as any)?.[i] === winner) {
            count++;
        }
    }

    // calculate percentage
    const percentage = ((count / (currentBlockWalletsList as any)?.length) * 100).toFixed(2);

    return (
        <div key={index} style={{
            // padding: '15px',
            borderRadius: '8px',
            textAlign: 'left',
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
        }}>
            {index > 0 && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#000000',
                    fontSize: '10px',
                    marginRight: '5px'
                }}>
                    {"*".repeat(8).split("").map((star, i) => (
                        <div key={i} style={{ lineHeight: '10px', fontSize: '20px' }}>*</div>
                    ))}
                </div>
            )}
            <div>
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#EC5409', marginBottom: '5px' }}>Block #{blockNumber}</div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#000000', fontSize: '14px', marginBottom: '5px' }}>
                    <PersonIcon style={{ marginRight: '4px', color: '#000000' }} /> {ensName ? ensName : winner ? (winner as any).slice(0,6)+"..."+(winner as any).slice(-4) : "Pending..."}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: '#000000', fontSize: '14px', marginBottom: '5px' }}>
                    <BoltIcon style={{ marginRight: '4px', color: '#000000' }} /> 
                    {count} ({percentage}%)
                </div>
            </div>
        </div>
    );
}
export default PreviousBlockWinnerCard;