import React, { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer/PageContainer";
import { useReadContract } from "wagmi";
import { formatUnits } from "viem";
import { Col, Row } from "antd";
import EthCoinContractABI from "../../abis/ethcoin.abi.json";
import MineModal from "./components/MineModal";
import FutureMineModal from "./components/FutureMineModal";
import BoltIcon from '@mui/icons-material/Bolt';
import HikingIcon from '@mui/icons-material/Hiking';
import { SONICCOIN_ADDRESS } from "../../utils/constants";
import ThreeExplosion from "../ThreeExplosion";
import NextBlockLeaderboardPlayer from "./components/NextBlockLeaderboardPlayer";
import PreviousBlockWinnerCard from "./components/PreviousBlockWinnerCard";
import CurrentBlockLeaderboardPlayer from "./components/CurrentBlockLeaderboardPlayer";
import styled from "styled-components";
import Footer from "../../components/Footer";
import { useMediaQuery } from "@mui/material";
import StatsIcon from "../../assets/img/stats-icon.svg";
import HistoryIcon from "../../assets/img/history-icon.svg";
import MinerIcon from "../../assets/img/miner-icon.svg";
import LeftSidebar from "./components/LeftSidebar";
import BlankModal from "./components/BlankModal";
import StatsModal from "./components/StatsModal";
import HistorySection from "./components/HistorySection";

const MiningButtonsContainer = styled.div`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    zIndex: 1;

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: 580px) {
        flex-direction: row;
        align-items: center;
    }
`;

const FarmPage = () => {

    const isMobile = useMediaQuery('(max-width: 580px)');

    const { data: currentBlockNumber } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'blockNumber',
        args: [],
        query: { refetchInterval: 1000 },
    });

    const { data: lastBlockTime } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'lastBlockTime',
        args: [],
        query: { refetchInterval: 1000 },
    });

    const { data: nextHalvingBlock } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'nextHalvingBlock',
        args: [],
    });

    const { data: miningReward } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'miningReward',
        args: [],
    });

    const { data: totalSupply } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'totalSupply',
        args: [],
    });

    const { data: currentBlockTotalEnergy } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'minersOfBlockCount',
        args: [Number(currentBlockNumber)],
        query: { refetchInterval: 1000 },
    });

    const { data: currentBlockWalletsList } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'minersOfBlock',
        args: [Number(currentBlockNumber)],
        query: { refetchInterval: 1000 },
    });


    // Count occurrences of each wallet address
    const currentBlockWalletCounts = new Map<string, number>();

    (currentBlockWalletsList as any)?.forEach((wallet: string) => {
        currentBlockWalletCounts.set(wallet, (currentBlockWalletCounts.get(wallet) || 0) + 1);
    });

    // Convert to an array and sort by occurrences in descending order
    const currentSortedUniqueWallets = Array.from(currentBlockWalletCounts.entries())
        .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
        .map(([wallet]) => wallet); // Map to get only the wallet addresses

    // Calculate the total mining power in the current block by summing all occurrences in currentBlockWalletCounts
    const totalMiningPower = Array.from(currentBlockWalletCounts.values()).reduce((acc, count) => acc + count, 0);

    // Calculate the top 4 wallets' mining power and their percentages
    const top4WalletData = Array.from(currentSortedUniqueWallets)
        .slice(0, 4)
        .map((walletAddress) => {
            const miningPower = currentBlockWalletCounts.get(walletAddress) || 0;
            const percentage = ((miningPower / totalMiningPower) * 100).toFixed(2);
            return {
                address: walletAddress,
                miningPower,
                percentage: parseFloat(percentage),
            };
        });

    // sum all the mining power of the top 4 wallets
    const top4MiningPower = top4WalletData.reduce((acc, { miningPower }) => acc + miningPower, 0);
    // sum all the percentage of the top 4 wallets
    const top4Percentage = top4WalletData.reduce((acc, { percentage }) => acc + percentage, 0);

    // count how much unique wallets are in the list
    const currentUniqueWallets = new Set(currentBlockWalletsList as any);
    const currentUniqueWalletsCount = currentUniqueWallets.size;

    const { data: nextBlockTotalEnergy } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'minersOfBlockCount',
        args: [Number(currentBlockNumber) + 1],
        query: { refetchInterval: 1000 },
    });

    const { data: nextBlockWalletsList } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'minersOfBlock',
        args: [Number(currentBlockNumber) + 1],
        query: { refetchInterval: 1000 },
    });

    // Count occurrences of each wallet address
    const walletCounts = new Map();

    (nextBlockWalletsList as any)?.forEach((wallet: string) => {
        walletCounts.set(wallet, (walletCounts.get(wallet) || 0) + 1);
    });

    // Convert to an array and sort by occurrences in descending order
    const sortedUniqueWallets = Array.from(walletCounts.entries())
        .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
        .map(([wallet]) => wallet); // Map to get only the wallet addresses

    // count how much unique wallets are in the list
    const uniqueWallets = new Set(nextBlockWalletsList as any);
    const uniqueWalletsCount = uniqueWallets.size;

    const [showMineModal, setShowMineModal] = useState(false);
    const [showFutureMineModal, setShowFutureMineModal] = useState(false);

    const [timeUntilNextBlock, setTimeUntilNextBlock] = useState("60s");

    useEffect(() => {
        const interval = setInterval(() => {
            const timeRemaining = lastBlockTime
                ? 60 - (Date.now() / 1000 - Number(lastBlockTime))
                : 60;
            setTimeUntilNextBlock(timeRemaining <= 0 ? "Soon" : timeRemaining.toFixed(0) + "s");
        }, 1000);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [lastBlockTime]);


    // Fetch the winner of the current block
    const { data: blockWinnerData } = useReadContract({
        abi: EthCoinContractABI,
        address: SONICCOIN_ADDRESS,
        functionName: 'selectedMinerOfBlock',
        args: [Number(currentBlockNumber)],
    });

    const [showCongratulatoryEffect, setShowCongratulatoryEffect] = useState(false);
    const [congratulatoryMiner, setCongratulatoryMiner] = useState<string | null>(null);

    // Trigger the congratulatory effect when `blockWinnerData` changes
    useEffect(() => {
        if (blockWinnerData && blockWinnerData !== "0x0000000000000000000000000000000000000000") {
            const winner = blockWinnerData as string;
            setCongratulatoryMiner(winner);
            setShowCongratulatoryEffect(true);

            // Reset the effect after 5 seconds
            const timer = setTimeout(() => setShowCongratulatoryEffect(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [blockWinnerData]);

    // countdown until next halving block (if total seconds is days then show days, if hours then show hours, if minutes then show minutes, if seconds then show seconds)
    const [timeUntilNextHalving, setTimeUntilNextHalving] = useState("14 days");
    useEffect(() => {
        const interval = setInterval(() => {
            const timeRemaining = nextHalvingBlock
                ? (Number(nextHalvingBlock) - Number(currentBlockNumber)) * 60
                : 0;

            if (timeRemaining <= 0) {
                setTimeUntilNextHalving("Soon");
            } else if (timeRemaining < 60) {
                setTimeUntilNextHalving(timeRemaining.toFixed(0) + " seconds");
            } else if (timeRemaining < 3600) {
                setTimeUntilNextHalving((timeRemaining / 60).toFixed(0) + " minutes");
            } else if (timeRemaining < 86400) {
                setTimeUntilNextHalving((timeRemaining / 3600).toFixed(0) + " hours");
            } else {
                setTimeUntilNextHalving((timeRemaining / 86400).toFixed(0) + " days");
            }
        }, 1000);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [nextHalvingBlock, currentBlockNumber]);


    // generate array with last 4 blocks
    const historyDataArray = [];
    for (let i = 0; i < (Number(currentBlockNumber) < 4 ? Number(currentBlockNumber) : 4); i++) {
        historyDataArray.push(currentBlockNumber ? (Number(currentBlockNumber) - (i + 1)) : 0);
    }

    const [showCurrentRoundPlayersModal, setShowCurrentRoundPlayersModal] = useState(false);
    const [showNextRoundPlayersModal, setShowNextRoundPlayersModal] = useState(false);
    const [showStatsModal, setShowStatsModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    return (
        <PageContainer>
            {showHistoryModal && (
                <BlankModal open={showHistoryModal} onClose={() => setShowHistoryModal(false)}>
                    <HistorySection historyDataArray={historyDataArray} />
                </BlankModal>
            )}
            {showCurrentRoundPlayersModal && (
                <BlankModal open={showCurrentRoundPlayersModal} onClose={() => setShowCurrentRoundPlayersModal(false)}>
                    <LeftSidebar
                        currentBlockNumber={currentBlockNumber ? (Number(currentBlockNumber)).toString() : '-----'}
                        uniqueWalletsCount={currentUniqueWalletsCount}
                        nextBlockTotalEnergy={currentBlockTotalEnergy}
                        sortedUniqueWallets={currentSortedUniqueWallets}
                        nextBlockWalletsList={currentBlockWalletsList}
                    />
                </BlankModal>
            )}
            {showNextRoundPlayersModal && (
                <BlankModal open={showNextRoundPlayersModal} onClose={() => setShowNextRoundPlayersModal(false)}>
                    <LeftSidebar
                        currentBlockNumber={currentBlockNumber ? (Number(currentBlockNumber) + 1).toString() : '-----'}
                        uniqueWalletsCount={uniqueWalletsCount}
                        nextBlockTotalEnergy={nextBlockTotalEnergy}
                        sortedUniqueWallets={sortedUniqueWallets}
                        nextBlockWalletsList={nextBlockWalletsList}
                    />
                </BlankModal>
            )}
            {showStatsModal && (
                <StatsModal open={showStatsModal} onClose={() => setShowStatsModal(false)}
                    currentBlockNumber={currentBlockNumber}
                    timeUntilNextBlock={timeUntilNextBlock}
                    miningReward={miningReward}
                    timeUntilNextHalving={timeUntilNextHalving}
                    nextHalvingBlock={nextHalvingBlock}
                    totalSupply={totalSupply}
                />
            )}
            {showMineModal && (
                <MineModal open={showMineModal} onClose={() => setShowMineModal(false)} />
            )}
            {showFutureMineModal && (
                <FutureMineModal open={showFutureMineModal} onClose={() => setShowFutureMineModal(false)} />
            )}
            <main className="h-full" style={{ minHeight: '100vh', color: '#EC5409', fontFamily: "Urbanist, ui-sans-serif" }}>

                {/* Top Section with borders */}
                {!isMobile && (
                    <div style={{
                        borderTop: '1px solid #000000',
                        borderBottom: '1px solid #000000',
                        padding: '10px 0',
                        marginBottom: '20px',
                    }}>
                        <Row justify="space-between" align="middle" style={{ textAlign: 'center' }}>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000000', textAlign: "left" }}>Block number</div>
                                <div style={{ fontSize: '28px', textAlign: "left" }}>#{currentBlockNumber ? currentBlockNumber.toString() : "-"}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000000' }}>Miner reward</div>
                                <div style={{ fontSize: '28px' }}>{miningReward ? formatUnits(miningReward as any, 18).toString() : "-"} SONIC</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000000' }}>Next halving in</div>
                                <div style={{ fontSize: '28px' }}>~{timeUntilNextHalving}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000000', textAlign: "right" }}>Next block in</div>
                                <div style={{ fontSize: '28px', textAlign: "right" }}>{timeUntilNextBlock}</div>
                            </div>
                        </Row>
                    </div>
                )}

                {/* Left Sidebar - Leaderboard */}
                <Row gutter={[16, 24]} style={{ marginTop: '20px', marginLeft: "0px", marginRight: "0px" }}>
                    {!isMobile && (
                        <LeftSidebar
                            currentBlockNumber={currentBlockNumber ? (Number(currentBlockNumber) + 1).toString() : '-----'}
                            uniqueWalletsCount={uniqueWalletsCount}
                            nextBlockTotalEnergy={nextBlockTotalEnergy}
                            sortedUniqueWallets={sortedUniqueWallets}
                            nextBlockWalletsList={nextBlockWalletsList}
                        />
                    )}

                    {/* Center - Grid Animation and History */}
                    <Col span={isMobile ? 24 : 18} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div style={{ textAlign: 'center', flexGrow: 1, position: 'relative', minHeight: isMobile ? "100vh" : '600px' }}>


                            <div style={{
                                padding: '10px 0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: isMobile ? "initial" : 'center',
                                color: '#000000',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                fontFamily: "Urbanist, ui-sans-serif",
                                width: '100%',
                                position: isMobile ? "absolute" : "static",
                                zIndex: 1
                            }}>
                                <span style={{ display: 'flex', alignItems: isMobile ? "initial" : 'center', gap: '8px', flex: "1", paddingLeft: "20px", flexDirection: isMobile ? "column" : "row", textAlign: isMobile ? "left" : "initial" }}>
                                    {currentBlockNumber ? "Block #" + currentBlockNumber.toString() : '-----'}
                                    {isMobile && (
                                        <div>
                                            <button onClick={() => setShowStatsModal(!showStatsModal)} style={{ padding: "0.375rem", fontSize: "0.875rem", borderRadius: "0.5rem", border: "1px solid #fff", marginRight: "10px" }}>
                                                <img src={StatsIcon} width={18} height={18} alt="Stats" style={{ filter: "invert(1)" }} />
                                            </button>
                                            <button onClick={() => setShowHistoryModal(!showHistoryModal)} style={{ padding: "0.375rem", fontSize: "0.875rem", borderRadius: "0.5rem", border: "1px solid #fff" }}>
                                                <img src={HistoryIcon} width={18} height={18} alt="Stats" style={{ filter: "invert(1)" }} />
                                            </button>
                                        </div>
                                    )}
                                </span>
                                <div onClick={() => setShowCurrentRoundPlayersModal(!showCurrentRoundPlayersModal)}>
                                    <HikingIcon style={{ fontSize: '24px', marginRight: '0 5px', color: "#000000" }} />
                                    <span>{currentUniqueWalletsCount}</span>
                                    <BoltIcon style={{ fontSize: '24px', marginRight: '0 5px', color: "#000000" }} />
                                    <span style={{ paddingRight: '20px', color: "#000000" }}>{currentBlockTotalEnergy ? currentBlockTotalEnergy.toString() : "-"}</span>
                                </div>
                            </div>

                            {!isMobile && (
                                <div style={{
                                    padding: '10px 0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'right',
                                    alignItems: 'center',
                                    color: '#FFFFFF',
                                    // fontSize: '20px',
                                    fontWeight: 'bold',
                                    gap: "1rem",
                                    flexWrap: 'wrap',
                                    fontFamily: "Urbanist, ui-sans-serif",
                                    width: '100%',
                                    alignContent: 'flex-end'
                                }}>
                                    {/* {Array.from(currentSortedUniqueWallets).slice(0, 4).map((userAddress, index) => ( */}
                                    {top4WalletData.map((user, index) => (
                                        <CurrentBlockLeaderboardPlayer
                                            key={index}
                                            index={index}
                                            userAddress={user.address}
                                            miningPower={user.miningPower}
                                            percentage={user.percentage}
                                        />
                                    ))}
                                    {currentSortedUniqueWallets.length > 4 && (
                                        <CurrentBlockLeaderboardPlayer
                                            index={5}
                                            userAddress={"Others"}
                                            miningPower={totalMiningPower - top4MiningPower}
                                            percentage={Number((100 - top4Percentage).toFixed(2))}
                                        />
                                    )}
                                </div>
                            )}

                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }}>
                                {/* {top4WalletData.length >= 4 && ( */}
                                <ThreeExplosion
                                    width="100%"
                                    height="100%"
                                    showCongratulatoryEffect={showCongratulatoryEffect}
                                    congratulatoryMiner={congratulatoryMiner || ""}
                                />
                                {/* )} */}
                            </div>

                            {/* Buttons Positioned Above Animation but at the Bottom */}
                            {isMobile && (
                                <MiningButtonsContainer style={{ width: "100%", justifyContent: "right", bottom: "60px", alignItems: "flex-end", flexDirection: "column" }}>
                                    <button onClick={() => setShowNextRoundPlayersModal(!showNextRoundPlayersModal)} style={{ padding: "0.375rem", fontSize: "1rem", color: "#fff", borderRadius: "0.5rem", alignItems: "center", border: "1px solid #fff", display: "flex", flexDirection: "row" }}>
                                        <img src={MinerIcon} width={18} height={18} alt="Stats" style={{ filter: "invert(1)", marginRight: "10px" }} />
                                        <p>{uniqueWalletsCount}</p>
                                    </button>
                                    <div>
                                        <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#ffffff', textAlign: "right" }}>Next block in</div>
                                        <div style={{ fontSize: '28px', textAlign: "right" }}>{timeUntilNextBlock}</div>
                                    </div>
                                </MiningButtonsContainer>
                            )}

                            <MiningButtonsContainer style={{ width: isMobile ? "100%" : undefined }}>
                                <button
                                    className="transparent-button"
                                    style={{
                                        width: isMobile ? "auto" : "14.5rem",
                                        flex: isMobile ? "1" : undefined,
                                        display: isMobile ? "flex" : undefined,
                                        justifyContent: isMobile ? "center" : undefined
                                    }}
                                    onClick={() => setShowMineModal(!showMineModal)}
                                >
                                    Mine
                                </button>
                                <button
                                    className="future-mine-button"
                                    style={{
                                        width: isMobile ? "auto" : "14.5rem",
                                        flex: isMobile ? "1" : undefined,
                                        display: isMobile ? "flex" : undefined,
                                        justifyContent: isMobile ? "center" : undefined
                                    }}
                                    onClick={() => setShowFutureMineModal(!showFutureMineModal)}
                                >
                                    Future Mine
                                </button>
                            </MiningButtonsContainer>
                        </div>

                        {/* History Component Below Animation */}
                        {!isMobile && (
                            <HistorySection historyDataArray={historyDataArray} />
                        )}
                    </Col>
                </Row>
            </main>
            <Footer />
        </PageContainer>
    );
}

export default FarmPage;
