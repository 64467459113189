import React from "react";
import { useMediaQuery } from "@mui/material";
import PreviousBlockWinnerCard from "./PreviousBlockWinnerCard";

interface HistorySectionProps {
    historyDataArray: any;
}

const HistorySection: React.FC<HistorySectionProps> = ({
    historyDataArray
}) => {

    const isMobile = useMediaQuery('(max-width: 580px)');

    return (
        <div style={{
            marginTop: '20px',
            fontFamily: "Urbanist, ui-sans-serif"
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 20px',
                background: "linear-gradient(270deg, #214E82 25%, #506077 50%, #EC5409 75%, #FFC966 100%)",
                color: '#ffffff',
                fontFamily: "Urbanist, ui-sans-serif"
            }}>
                <span style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '20px', color: "#000000" }}>History</span>
                <button onClick={() => window.open("/history", "_self")} style={{ fontSize: '20px', background: 'none', border: 'none', cursor: 'pointer',  color: "#000000" }}>More &rarr;</button>
            </div>
            {/* <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: "row", gap: '10px', padding: '10px', marginLeft: '20px', marginRight: '20px' }}> */}
            <div style={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(4, 1fr)',
                gridTemplateRows: isMobile ? 'repeat(4, 1fr)' : 'auto',
                gap: '10px',
                padding: '10px',
                marginRight: '20px',
                overflow: "hidden"
            }}>
                {historyDataArray.map((entry: any, index: any) => (
                    <PreviousBlockWinnerCard key={index} index={isMobile ? index+1 : index} blockNumber={entry} />
                ))}
            </div>
        </div>
    );
}
export default HistorySection;