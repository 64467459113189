import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

interface ThreeExplosionProps {
    width?: string | number;
    height?: string | number;
    showCongratulatoryEffect?: boolean;
    congratulatoryMiner?: string;
}

const ThreeExplosion: React.FC<ThreeExplosionProps> = ({ width = '100%', height = '100%', showCongratulatoryEffect = false, congratulatoryMiner }) => {
    const mountRef = useRef<HTMLDivElement | null>(null);
    const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
    const textMeshRef = useRef<THREE.Mesh | null>(null);

    useEffect(() => {
        if (!mountRef.current) return;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(0, 0, 40);
        camera.lookAt(0, 0, 0);

        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.domElement.style.position = 'absolute';
        renderer.domElement.style.top = '50%';
        renderer.domElement.style.left = '50%';
        renderer.domElement.style.transform = 'translate(-50%, -50%)';
        mountRef.current.appendChild(renderer.domElement);
        rendererRef.current = renderer;

        const colors = [new THREE.Color("#EC5409"), new THREE.Color("#1E6EF7")];
        const numLayers = 30;
        const layerSpacing = 0.5;

        const torusGroup = new THREE.Group();
        for (let i = 0; i < numLayers; i++) {
            const size = 1 + i * layerSpacing;
            const torusGeometry = new THREE.TorusGeometry(size, 0.08, 16, 100);
            const color = colors[0].clone().lerp(colors[1], i / numLayers);
            const material = new THREE.MeshBasicMaterial({ color, transparent: true, opacity: 1 });
            const torus = new THREE.Mesh(torusGeometry, material);
            torusGroup.add(torus);
        }

        scene.add(torusGroup);

        // Congratulatory text setup
        const createCongratulatoryText = () => {
            const fontLoader = new FontLoader();
            fontLoader.load('/fonts/helvetiker_bold.typeface.json', (font) => {
                const textGeometry = new TextGeometry(`Congratulations\n${congratulatoryMiner?.slice(0, 6)}...${congratulatoryMiner?.slice(-6)}`, {
                    font,
                    size: 1,
                    height: 0.5,
                    curveSegments: 12,
                });

                const textMaterial = new THREE.MeshBasicMaterial({ color: '#214E82' });

                const textMesh = new THREE.Mesh(textGeometry, textMaterial);
                textGeometry.computeBoundingBox(); // Compute bounding box to center the text
                const textWidth = (textGeometry.boundingBox?.max.x || 0) - (textGeometry.boundingBox?.min.x || 0);
                textMesh.position.set(-textWidth / 2, 0, 0); // Center horizontally
                textMesh.scale.set(0.5, 0.5, 0.5);  // Start small
                scene.add(textMesh);
                textMeshRef.current = textMesh;
            });
        };

        if (showCongratulatoryEffect) {
            createCongratulatoryText();
        }

        // Animation loop
        const animate = () => {
            const time = performance.now() / 1000;

            if (showCongratulatoryEffect) {
                torusGroup.children.forEach((torus, index) => {
                    const mesh = torus as THREE.Mesh;
                    const fadeDelay = index * 0.2;
                    const elapsed = time - fadeDelay;
                    if (elapsed > 0) {
                        const fadeFactor = Math.max(0, 1 - elapsed * 0.5);
                        const material = mesh.material as THREE.MeshBasicMaterial;
                        if (material.opacity !== undefined) {
                            material.opacity = fadeFactor;
                        }
                        mesh.scale.set(
                            1 + elapsed * 0.1,
                            1 + elapsed * 0.1,
                            1
                        );
                    }
                });

                if (textMeshRef.current) {
                    const textScale = Math.min(1, 0.5 + (time - 1) * 0.2); // Increase scale gradually
                    const textOpacity = Math.min(1, (time - 1) * 0.5);      // Fade in text gradually
                    textMeshRef.current.scale.set(textScale, textScale, textScale);
                    const textMaterial = textMeshRef.current.material as THREE.MeshBasicMaterial;
                    if (textMaterial.opacity !== undefined) {
                        textMaterial.opacity = textOpacity;
                    }
                }
            } else {
                torusGroup.children.forEach((torus, index) => {
                    const mesh = torus as THREE.Mesh;
                    const scaleFactor = 1 + 0.02 * Math.sin(performance.now() / 500 + index * 0.1);
                    mesh.scale.set(scaleFactor, scaleFactor, scaleFactor);
                });
            }

            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        animate();

        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (mountRef.current && rendererRef.current?.domElement.parentNode === mountRef.current) {
                mountRef.current.removeChild(rendererRef.current.domElement);
            }
            rendererRef.current?.dispose();
            scene.clear();
        };
    }, [showCongratulatoryEffect, congratulatoryMiner]);

    return (
        <div style={{ position: 'relative', width, height, overflow: 'hidden' }}>
            <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default ThreeExplosion;
