import React from "react";
import BoltIcon from '@mui/icons-material/Bolt';
import { useEnsName } from "wagmi";

interface NextBlockLeaderboardPlayerProps {
    index: number;
    userAddress: string;
    walletsList: string[];
}

const NextBlockLeaderboardPlayer: React.FC<NextBlockLeaderboardPlayerProps> = ({
    index,
    userAddress,
    walletsList,
}) => {

    const { data: ensName } = useEnsName({ address: userAddress as any })

    // count number of occurences of userAddress in walletsList
    let count = 0;
    for (let i = 0; i < walletsList.length; i++) {
        if (walletsList[i] === userAddress) {
            count++;
        }
    }

    // calculate percentage
    const percentage = ((count / walletsList.length) * 100).toFixed(2);

    return (
        <div key={index} style={{
            padding: '10px',
            fontSize: '16px',
            borderTop: index > 0 ? '1px dashed #6c6c6c' : 'none',
            textAlign: 'left',
            fontFamily: "Urbanist, ui-sans-serif"
        }}>
            <div style={{ color: '#EC5409', fontWeight: 'bold' }}>{ensName ? ensName : userAddress.slice(0,6)+"..."+userAddress.slice(-4)}</div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                color: '#000000',
                fontSize: '14px'
            }}>
                <BoltIcon style={{ fontSize: '14px', marginRight: '4px', color: '#000000' }} />
                {count} ({percentage}%)
            </div>
        </div>
    );
}
export default NextBlockLeaderboardPlayer;