import React, { useState, useEffect, useRef } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import "./header.css";
import SoniccoinLogo from "../../assets/img/soniccoin-text-logo.png";
import HowItWorks from '../../views/MainPage/components/HowItWorks';

const soundCloudTracks = [
    'https://soundcloud.com/djzeu5/pure-vinyl-set-11-deep-house-dub-techno-deep-techno', // replace with actual track links
    'https://soundcloud.com/djzeu5/pure-vinyl-set-10-deep-house-dub-techno-minimal',
    'https://soundcloud.com/djzeu5/pure-vinyl-set-9-deep-house-dub-techno'
];

const Header = () => {
    const [showHowItWorks, setShowHowItWorks] = useState(false);
    const [isMusicOn, setIsMusicOn] = useState(false);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const playerRef = useRef(null);

    const toggleMusic = () => {
        setIsMusicOn(!isMusicOn);
    };

    // Initialize SoundCloud player
    useEffect(() => {
        if (!playerRef.current) {
            playerRef.current = window.SC.Widget(document.getElementById('soundcloud-player'));
        }

        playerRef.current.load(soundCloudTracks[currentTrackIndex], {
            auto_play: isMusicOn,
        });

        playerRef.current.bind(window.SC.Widget.Events.FINISH, () => {
            setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % soundCloudTracks.length);
        });
    }, [currentTrackIndex]);

    useEffect(() => {
        if (playerRef.current) {
            if (isMusicOn) {
                playerRef.current.play();
            } else {
                playerRef.current.pause();
            }
        }
    }, [isMusicOn]);

    return (
        <nav className="header flex justify-between items-center p-4 bg-primary">
            {showHowItWorks && (
                <HowItWorks open={showHowItWorks} onClose={() => setShowHowItWorks(false)} />
            )}
            <div className="flex items-center gap-4">
                <a href="/">
                    <img
                        alt="Soniccoin"
                        width={140}
                        height={32}
                        src={SoniccoinLogo}
                        className="mr-4"
                    />
                </a>
            </div>
            <div className="flex items-center gap-4">
                {/* Music Toggle Button */}
                <button className="icon-button" onClick={toggleMusic}>
                    {isMusicOn ? (
                        <MusicNoteIcon style={{ color: '#000000' }} />
                    ) : (
                        <MusicOffIcon style={{ color: '#000000' }} />
                    )}
                </button>

                {/* Help Icon */}
                <button className="icon-button" onClick={() => setShowHowItWorks(true)}>
                    <HelpOutlineIcon style={{ color: '#000000' }} />
                </button>

                {/* Connect Wallet Button */}
                <ConnectButton.Custom>
                    {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        authenticationStatus,
                        mounted,
                    }) => {
                        const ready = mounted && authenticationStatus !== 'loading';
                        const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');

                        return (
                            <div {...(!ready && { 'aria-hidden': true, style: { opacity: 0, pointerEvents: 'none', userSelect: 'none' } })}>
                                {(() => {
                                    if (!connected) {
                                        return (
                                            <button
                                                className="connect-wallet-button"
                                                type="button"
                                                onClick={openConnectModal}
                                            >
                                                Connect wallet
                                            </button>
                                        );
                                    }

                                    if (chain.unsupported) {
                                        return (
                                            <button
                                                className="text-sm text-black"
                                                type="button"
                                                onClick={openChainModal}
                                            >
                                                [wrong network]
                                            </button>
                                        );
                                    }

                                    return (
                                        <button onClick={openAccountModal} type="button" className="connect-wallet-button ">
                                            <span>{account.displayName}</span>
                                        </button>
                                    );
                                })()}
                            </div>
                        );
                    }}
                </ConnectButton.Custom>
            </div>
            {/* SoundCloud Player */}
            <iframe
                id="soundcloud-player"
                width="100%"
                height="0"
                style={{ display: 'none' }}
                scrolling="no"
                frameBorder="no"
                src={`https://w.soundcloud.com/player/?url=${soundCloudTracks[currentTrackIndex]}&auto_play=false`}
            ></iframe>
        </nav>
    );
};

export default Header;
