import React, { useState } from "react";

interface ButtonMenuProps {
  active: boolean;
  onClick: () => void;
  // text: string;
  children: React.ReactNode;
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({ onClick, active, children} ) => {
  return (
    <div 
    onClick={onClick} 
    className={`cursor-pointer button-menu-normal ${active ? "button-menu-selected" : ""}`}>
      {children}
    </div>
  );
}
export default ButtonMenu;