import React, { useRef, useState } from "react";
import { useEnsName } from "wagmi";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import styled from "styled-components";
import { zeroAddress } from "viem";

const OuterContainer = styled.div<{ margin: string; }>`
    padding: 4px 8px;
    fontSize: 16px;
    background: #fe9a4d;
    cursor: pointer;
    flexWrap: wrap;
    textAlign: left;
    fontFamily: Urbanist, ui-sans-serif;
    z-index: 1;
    margin-left: ${({ margin }) => margin};

    &:hover {
        background: rgba(255, 255, 0, 0.2);
    }
`;

const InfoContainer = styled.div<{ left: number; top: number, width: number }>`
    display: ${({ left, top }) => (left && top ? "block" : "none")};
    position: absolute;
    left: ${({ left }) => left}px;
    top: ${({ top }) => top}px;
    width: ${({ width }) => width}px;
    padding: 8px;
    background: #fe9a4d;
    color: black;
    border-radius: 4px;
    z-index: 2;
`;

interface CurrentBlockLeaderboardPlayerProps {
    index: number;
    userAddress: string;
    miningPower: number;
    percentage: number;
}

const CurrentBlockLeaderboardPlayer: React.FC<CurrentBlockLeaderboardPlayerProps> = ({
    index,
    userAddress,
    miningPower,
    percentage
}) => {

    const { data: ensName } = useEnsName({ address: userAddress === "Others" ? zeroAddress : userAddress as any })

    const outerRef = useRef<HTMLDivElement>(null);
    const [hoverPosition, setHoverPosition] = useState<{ left: number; top: number }>({
        left: 0,
        top: 0,
    });

    const handleMouseEnter = () => {
        if (outerRef.current) {
            const rect = outerRef.current.getBoundingClientRect();
            setHoverPosition({
                left: rect.left - (userAddress === "Others" ? 700 : 870),  // Adjust based on the container width
                top: rect.top - (userAddress === "Others" ? 180 : 190),
            });
        }
    };

    const handleMouseLeave = () => {
        setHoverPosition({ left: 0, top: 0 });
    };

    return (
        <OuterContainer key={index} margin={userAddress === "Others" ? "auto" : "none"}
            ref={outerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div style={{ color: '#000000', fontWeight: 'bold' }}>
                <AccessibilityNewIcon style={{ fontSize: '24px' }} />
                {ensName ? ensName : userAddress === "Others" ? "Others" : userAddress.slice(0, 6) + "..." + userAddress.slice(-4)}
            </div>
            <InfoContainer left={hoverPosition.left} top={hoverPosition.top} width={userAddress === "Others" ? 170 : 400} className="info-container">
                {userAddress !== "Others" && (
                    <p style={{ textAlign: "right" }}>{userAddress}</p>
                )}
                <p style={{ textAlign: "right", color: "#000000 !important" }}>Mining Power: {miningPower}</p>
                <p style={{ textAlign: "right",  color: "#000000 !important"  }}>{percentage}%</p>
                {/* Add more details as needed */}
            </InfoContainer>
        </OuterContainer>
    );
}
export default CurrentBlockLeaderboardPlayer;