import { http, createConfig } from 'wagmi'
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  safeWallet,
  trustWallet,
  rabbyWallet,
} from '@rainbow-me/rainbowkit/wallets';

export const soneiumTestnet = {
  id: 146,
  name: 'Sonic Mainnet',
  nativeCurrency: { name: 'Sonic', symbol: 'S', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.soniclabs.com'] },
  },
  blockExplorers: {
    default: { name: 'SonicScan', url: 'https://sonicscan.com' },
  },
//   multicall: {
//     address: '0xcA11bde05977b3631167028862bE2a173976CA11', // Multicall3 address on Sonic Mainnet
//     blockCreated: 60, // Block number where Multicall3 was deployed on Sonic
// },
}

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        rabbyWallet,
        metaMaskWallet,
        rainbowWallet,
        // walletConnectWallet,
        coinbaseWallet,
        injectedWallet,
        safeWallet,
        trustWallet,
      ],
    },
  ],
  {
    appName: 'Soniccoin',
    projectId: 'YOUR_PROJECT_ID',
  }
);

export const config = createConfig({
  chains: [soneiumTestnet],
  connectors,
  transports: {
    [soneiumTestnet.id]: http(),
  },
  publicClient: http({ chainId: soneiumTestnet.id, url: soneiumTestnet.rpcUrls.default.http[0] }),
})