import React, { useEffect, useRef, useState } from "react";
import { useAccount, useBalance, useReadContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { SONICCOIN_ADDRESS } from "../../../utils/constants";
import { formatUnits, parseUnits } from "viem";
import { notifyError, notifySuccess } from "../../../hooks/utils";
import ButtonMenu from "../../../components/ButtonMenu";
import EthCoinContractABI from "../../../abis/ethcoin.abi.json";
import BoltIcon from '@mui/icons-material/Bolt';
import CloseIcon from '@mui/icons-material/Close';

interface MineModalProps {
  open: boolean;
  onClose: () => void;
}

const MineModal: React.FC<MineModalProps> = ({ open, onClose }) => {

  const account = useAccount();

  const userBalance = useBalance()

  const { data: miningCost } = useReadContract({
    abi: EthCoinContractABI,
    address: SONICCOIN_ADDRESS,
    functionName: 'mineCost',
    args: [], // args is an array of arguments to pass to the function
  });

  const [totalBlocksToMine, setTotalBlocksToMine] = useState(1);

  const [placingTrade, setPlacingTrade] = useState(false);

  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });

  // Log the transaction arguments and hash for debugging
  useEffect(() => {
    if (hash) {
      console.log("Transaction hash:", hash);
      notifySuccess("Mining placed!");
    }
    if (error) {
      console.error("Error during contract write:", error);
      notifyError("An error occurred while trying to place trade.");
    }
    if (isConfirmed) {
      console.log("Transaction confirmed!");
      notifySuccess("Mining confirmed!");
    }
  }, [hash, error]);


  const handleMine = async () => {
    if (!account) {
      // Redirect to login or show a message
      alert("You need to sign in to trade.");
      return;
    }

    try {
      setPlacingTrade(true);

      const transactionArgs = {
        address: SONICCOIN_ADDRESS,
        abi: EthCoinContractABI,
        functionName: 'mine',
        args: [
          totalBlocksToMine
        ],
        value: parseUnits((totalBlocksToMine * Number(formatUnits(miningCost as any ?? "0", 18))).toString(), 18), // 0.0001 $S
      };

      writeContract(transactionArgs as any);
    } catch (error) {
      console.error("Error placing trade:", error);
      notifyError("An error occurred while trying to place trade.");
    } finally {
      setPlacingTrade(false);
    }
  };


  // MODAL OPEN AND CLOSE
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="text-sm fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-black"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "none" }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: "1", justifyContent: "left", display: "flex" }}>Mine in the Next Block</div>
          <div onClick={onClose}><CloseIcon/></div>
        </div>

        <p style={{textAlign: "left"}}>Mining Power:</p>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center", marginBottom: "20px" }}>
          <ButtonMenu onClick={() => setTotalBlocksToMine(1)} active={totalBlocksToMine === 1}>1 <BoltIcon style={{ fontSize: '18px', marginLeft: '-2px' }} /></ButtonMenu>
          <ButtonMenu onClick={() => setTotalBlocksToMine(10)} active={totalBlocksToMine === 10}>10 <BoltIcon style={{ fontSize: '18px', marginLeft: '-2px' }} /></ButtonMenu>
          <ButtonMenu onClick={() => setTotalBlocksToMine(50)} active={totalBlocksToMine === 50}>50 <BoltIcon style={{ fontSize: '18px', marginLeft: '-2px' }} /></ButtonMenu>
          <ButtonMenu onClick={() => setTotalBlocksToMine(100)} active={totalBlocksToMine === 100}>100 <BoltIcon style={{ fontSize: '18px', marginLeft: '-2px' }} /></ButtonMenu>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: "1", justifyContent: "left", display: "flex" }}>Wallet Balance</div>
          <div>{userBalance ? userBalance.data ? (userBalance.data.value.toString()) : "0" : "-"} S</div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: "1", justifyContent: "left", display: "flex" }}>Mining Cost</div>
          <div>{(totalBlocksToMine * Number(formatUnits(miningCost as any ?? "0", 18))).toLocaleString("en-US", { maximumSignificantDigits: 2 })} S</div>
        </div>

        <button className={`transparent-button`}
        style={{width: '100%', marginTop: '10px'}}
          disabled={placingTrade || isPending || isConfirming}
          onClick={() => handleMine()}>
          {(placingTrade || isPending || isConfirming) ? "Mining..." : "Mine"}
        </button>
      </div>
    </div>
  );
}
export default MineModal;